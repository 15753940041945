import React from 'react'

const Footer = () => {
  return (
    <footer>
        <div class="container">
            <div class="footer-bottom">
                <div class="row">
                    <div class="col-md-12">
                        <p class="copyright text-white">©2024 Datum Discovery TM All Rights Reserved</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>  
    )
}

export default Footer