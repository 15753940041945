import React from 'react';
import  avatar from '../assets/img/avatar.png';
import instructor2 from '../assets/img/instructor2image.png'

import { InstructorData, InstructorData2 } from '../constant/constantdata';


const FifthFold = () => {
  return (
    <>
            <div className="faq-area">
              <div className="section-title">
                <h2>{InstructorData.heading}</h2>
              </div>
              <div className="row">
                <div className="col-lg-12">
                <div className="author_two">
              <div className="d-md-flex justify-content-md-start">
                
                <div className="author-del-2">
                  <h2 className="fs-24 mb-16 author-name">{InstructorData.name}</h2>
                  <p className="fs-16 fw-400 text-white author-dec mb-3">{InstructorData.description}</p>
                  <div className="d-flex align-items-center justify-content-between">
                    <a 
                      className="text-warning fs-16" 
                      href={InstructorData.linkdin} 
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      Read More..
                    </a>
                    <div className="d-flex align-items-center gap-2">
                      <a 
                        href={InstructorData.linkdin} 
                        className="btn bg-white btn-round btn-icon-md lh-40 social-svg" 
                        target="_blank" 
                        rel="noopener noreferrer"
                      >
                        <svg 
                          width="24" 
                          height="24" 
                          viewBox="0 0 24 24" 
                          fill="none" 
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path 
                            fillRule="evenodd" 
                            clipRule="evenodd" 
                            d="M9.429 8.969H13.143V10.819C13.678 9.755 15.05 8.799 17.111 8.799C21.062 8.799 22 10.917 22 14.803V22H18V15.688C18 13.475 17.465 12.227 16.103 12.227C14.214 12.227 13.429 13.572 13.429 15.687V22H9.429V8.969ZM2.57 21.83H6.57V8.799H2.57V21.83ZM7.143 4.55C7.14315 4.88528 7.07666 5.21724 6.94739 5.52659C6.81812 5.83594 6.62865 6.11651 6.39 6.352C5.9064 6.83262 5.25181 7.10165 4.57 7.1C3.88939 7.09954 3.23631 6.8312 2.752 6.353C2.51421 6.11671 2.32539 5.83582 2.19634 5.52643C2.0673 5.21704 2.00058 4.88522 2 4.55C2 3.873 2.27 3.225 2.753 2.747C3.23689 2.26816 3.89024 1.9997 4.571 2C5.253 2 5.907 2.269 6.39 2.747C6.872 3.225 7.143 3.873 7.143 4.55Z" 
                            fill="#0A66C2"
                          />
                        </svg>
                      </a>
                      <a 
                        href={InstructorData.Instagram} 
                        className="btn bg-white btn-round btn-icon-md lh-40 social-svg" 
                        target="_blank" 
                        rel="noopener noreferrer"
                      >
                        <svg 
                          xmlns="http://www.w3.org/2000/svg" 
                          width="24" 
                          height="24" 
                          viewBox="0 0 102 102" 
                          id="instagram"
                        >
                          <defs>
                            <radialGradient 
                              id="a" 
                              cx="6.601" 
                              cy="99.766" 
                              r="129.502" 
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop offset=".09" stopColor="#fa8f21" />
                              <stop offset=".78" stopColor="#d82d7e" />
                            </radialGradient>
                            <radialGradient 
                              id="b" 
                              cx="70.652" 
                              cy="96.49" 
                              r="113.963" 
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop offset=".64" stopColor="#8c3aaa" stopOpacity="0" />
                              <stop offset="1" stopColor="#8c3aaa" />
                            </radialGradient>
                          </defs>
                          <path 
                            fill="url(#a)" 
                            d="M25.865,101.639A34.341,34.341,0,0,1,14.312,99.5a19.329,19.329,0,0,1-7.154-4.653A19.181,19.181,0,0,1,2.5,87.694,34.341,34.341,0,0,1,.364,76.142C.061,69.584,0,67.617,0,51s.067-18.577.361-25.14A34.534,34.534,0,0,1,2.5,14.312,19.4,19.4,0,0,1,7.154,7.154,19.206,19.206,0,0,1,14.309,2.5,34.341,34.341,0,0,1,25.862.361C32.422.061,34.392,0,51,0s18.577.067,25.14.361A34.534,34.534,0,0,1,87.691,2.5a19.254,19.254,0,0,1,7.154,4.653A19.267,19.267,0,0,1,99.5,14.309a34.341,34.341,0,0,1,2.14,11.553c.3,6.563.361,8.528.361,25.14s-.061,18.577-.361,25.14A34.5,34.5,0,0,1,99.5,87.694,20.6,20.6,0,0,1,87.691,99.5a34.342,34.342,0,0,1-11.553,2.14c-6.557.3-8.528.361-25.14.361s-18.577-.058-25.134-.361"
                          />
                          <path 
                            fill="url(#b)" 
                            d="M25.865,101.639A34.341,34.341,0,0,1,14.312,99.5a19.329,19.329,0,0,1-7.154-4.653A19.181,19.181,0,0,1,2.5,87.694,34.341,34.341,0,0,1,.364,76.142C.061,69.584,0,67.617,0,51s.067-18.577.361-25.14A34.534,34.534,0,0,1,2.5,14.312,19.4,19.4,0,0,1,7.154,7.154,19.206,19.206,0,0,1,14.309,2.5,34.341,34.341,0,0,1,25.862.361C32.422.061,34.392,0,51,0s18.577.067,25.14.361A34.534,34.534,0,0,1,87.691,2.5a19.254,19.254,0,0,1,7.154,4.653A19.267,19.267,0,0,1,99.5,14.309a34.341,34.341,0,0,1,2.14,11.553c.3,6.563.361,8.528.361,25.14s-.061,18.577-.361,25.14A34.5,34.5,0,0,1,99.5,87.694,20.6,20.6,0,0,1,87.691,99.5a34.342,34.342,0,0,1-11.553,2.14c-6.557.3-8.528.361-25.14.361s-18.577-.058-25.134-.361"
                          />
                          <path 
                            fill="#fff" 
                            d="M461.114,477.413a12.631,12.631,0,1,1,12.629,12.632,12.631,12.631,0,0,1-12.629-12.632m-6.829,0a19.458,19.458,0,1,0,19.458-19.458,19.457,19.457,0,0,0-19.458,19.458m35.139-20.229a4.547,4.547,0,1,0,4.549-4.545h0a4.549,4.549,0,0,0-4.547,4.545m-30.99,51.074a20.943,20.943,0,0,1-7.037-1.3,12.547,12.547,0,0,1-7.193-7.19,20.923,20.923,0,0,1-1.3-7.037c-.184-3.994-.22-5.194-.22-15.313s.04-11.316.22-15.314a21.082,21.082,0,0,1,1.3-7.037,12.54,12.54,0,0,1,7.193-7.193,20.924,20.924,0,0,1,7.037-1.3c3.994-.184,5.194-.22,15.309-.22s11.316.039,15.314.221a21.082,21.082,0,0,1,7.037,1.3,12.541,12.541,0,0,1,7.193,7.193,20.926,20.926,0,0,1,1.3,7.037c.184,4,.22,5.194.22,15.314s-.037,11.316-.22,15.314a21.023,21.023,0,0,1-1.3,7.037,12.547,12.547,0,0,1-7.193,7.19,20.925,20.925,0,0,1-7.037,1.3c-3.994.184-5.194.22-15.314.22s-11.316-.037-15.309-.22m-.314-68.509a27.786,27.786,0,0,0-9.2,1.76,19.373,19.373,0,0,0-11.083,11.083,27.794,27.794,0,0,0-1.76,9.2c-.187,4.04-.229,5.332-.229,15.623s.043,11.582.229,15.623a27.793,27.793,0,0,0,1.76,9.2,19.374,19.374,0,0,0,11.083,11.083,27.813,27.813,0,0,0,9.2,1.76c4.042.184,5.332.229,15.623.229s11.582-.043,15.623-.229a27.8,27.8,0,0,0,9.2-1.76,19.374,19.374,0,0,0,11.083-11.083,27.716,27.716,0,0,0,1.76-9.2c.184-4.043.226-5.332.226-15.623s-.043-11.582-.226-15.623a27.786,27.786,0,0,0-1.76-9.2,19.379,19.379,0,0,0-11.08-11.083,27.748,27.748,0,0,0-9.2-1.76c-4.041-.185-5.332-.229-15.621-.229s-11.583.043-15.626.229" 
                            transform="translate(-422.637 -426.196)"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='author-del-img'>
                <img 
                  src={avatar}
                  className="me-md-40 mb-md-0 mb-20 author-pic" 
                  alt="Nirmal Pant" 
                />
                </div>
              </div>
            </div>
                </div>
              </div>
            </div>
    </>
  );
};

export default FifthFold;
