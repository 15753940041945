import React from 'react';
const CourseFeature = () => {
  return (
    <>
<div class="feature-container">
  <div class="feature-header">Free Lifetime Access</div>
  <div class="feature-box">
    <div class="feature-content">
      <div class="feature-item">
        <h3>No Experience Needed</h3>
        <p>Start from scratch and build up</p>
      </div>
      <div class="feature-item">
        <h3>Flexible Schedule</h3>
        <p>Learn at your own pace</p>
      </div>
      <div class="feature-item">
        <h3>Get Job-Ready</h3>
        <p>Acquire essential job skills</p>
      </div>
    </div>
  </div>
</div>

    </>
  );
};

export default CourseFeature;
