import dautamcertificate from '../assets/img/dautamcertificate.png';
import videoLearning from '../assets/img/video-learning.png';
import herobanner from '../assets/img/herobanner2.png';
import b from '../assets/img/testimonials/1.png';
import c from '../assets/img/testimonials/2.png';
import d from '../assets/img/testimonials/3.png';
import e from '../assets/img/testimonials/4.png';
import f from '../assets/img/testimonials/5.png';
import g from '../assets/img/testimonials/6.png';


export const firstData = {
    description: "SQL and Power BI Mastery: Your Step-by-Step Guide from ",
    description_span:"Start to Finish",
    para1: "Ready to level up your data skills? This course is your gateway to mastering both SQL and Power BI, starting from the basics and moving all the way to advanced techniques. Whether you're just beginning your data journey or looking to enhance your expertise, we’ve got you covered.",
    para_rating: 4.8,
    para_rating_number: 1901,
    last_updated: "Sep 9, 2024",
    en: "English",
    para_2: "Free Lifetime Access"
};
  
export const SecondData = {
  heading: "What You will learn?",
  list: [
    "End-to-End Power BI Project Process: From Scoping to Deployment",
    "Mastering Data Visualization Techniques with Power BI",
    "Integrating Power BI with Other Tools and Platforms",
    "Advanced DAX and Data Modeling Techniques",
    "Creating Interactive Dashboards and Reports",
    // SQL Learning Outcomes
    "Understanding the fundamentals of SQL and database management systems",
    "Executing complex SQL queries and data manipulation techniques",
    "Creating and managing databases, tables, and relationships",
    "Mastering SQL joins, subqueries, and advanced retrieval techniques",
    "Using SQL with Power BI for real-time data analysis and reporting",
    "Applying SQL aggregation, grouping, and filtering functions",
    "Working with live projects to implement SQL in a practical environment"
  ]
};


export const testimonialData = {
    heading: "What do learners say about this course?",
    testimonials: [
      {
        img : g,
        name: "Sharayu Khanvilkar",
        review: "I would like to extend my heartfelt thanks to Mr. Nirmal Pant for conducting an excellent Power BI training course. One of my friends referred me to Mr. Pant's",
        link: "https://courses.datumdiscovery.com/courses/Master-Class-Power-BI-63d1c288e4b09b42453a4fbe"
      },
      {
        img : b,
        name: "Manisha S",
        review: "The place where one's search comes to an end. And begins a journey of in-depth learning of the Data Visualization Tool - Power BI. Nirmal Sir has been instrumental",
        link: "https://courses.datumdiscovery.com/courses/Master-Class-Power-BI-63d1c288e4b09b42453a4fbe"
      },
      {
        img : c,
        name: "Girija Naik",
        review: "Thanks for a great training course. Excellent and professional training course of Power BI. I found this Power BI training session excellent! Mr. Nirmal Pant's",
        link: "https://courses.datumdiscovery.com/courses/Master-Class-Power-BI-63d1c288e4b09b42453a4fbe"
      },
      {
        img : d,
        name: "Syed Amjad Ali",
        review: "I found Mastering Power BI course on this platform very engaging and up-to-date. Mr. Nirmal Pant explanations are clear, making complex concepts easy to grasp.",
        link: "https://courses.datumdiscovery.com/courses/Master-Class-Power-BI-63d1c288e4b09b42453a4fbe"
      },
      {
        img : e,
        name: "Nickey Raj",
        review: "Learning Power BI under the guidance of Mr. Nirmal Pant was an exceptional experience. Mr. Pant’s deep expertise in the subject matter was excelled",
        link: "https://courses.datumdiscovery.com/courses/Master-Class-Power-BI-63d1c288e4b09b42453a4fbe"
      },
      {
        img : f,
        name: "Karthikeyan Vellaiyan",
        review: "This training has had a significant impact on my professional growth. I now feel confident in my ability to leverage Power BI for data-driven business landscape.",
        link: "https://courses.datumdiscovery.com/courses/Master-Class-Power-BI-63d1c288e4b09b42453a4fbe"
      },
      {
        img : g,
        name: "Meghana Bothe",
        review: "Thank you for a great course Nirmal. Personal Coaching with lots of opportunities to ask questions which made this enjoyable learning. This course has more",
        link: "https://courses.datumdiscovery.com/courses/Master-Class-Power-BI-63d1c288e4b09b42453a4fbe"
      }
    ]
};    
  
export const prerequisitesData = {
  heading: "What are the prerequisites for the Power BI & SQL course?",
  list: [
    "Basic familiarity with Excel or any other spreadsheet software",
    "A fundamental understanding of data analysis and database management concepts",
    "No prior experience with Power BI or SQL is required, but familiarity with databases or any programming language can be helpful",
    "Access to a computer with Power BI Desktop and MySQL (or any other SQL database software) installed",
    "A desire to learn how to create data-driven insights, perform data analysis, and work with real-world data using Power BI and SQL"
  ]
};


export const ForthData = {
  heading: "Key Skills Covered",
  skills: [
    "Data Cleaning using Power Query Editor",
    "Data Analysis using Data Modelling & DAX (Data Analysis Expression)",
    "Power BI Desktop",
    "Power BI Services",
    "Reports & Dashboards",
    "Dashboard Designing Techniques",
    // SQL Skills
    "SQL Query Writing",
    "Database Design and Normalization",
    "Data Manipulation (INSERT, UPDATE, DELETE)",
    "Complex Joins and Subqueries",
    "Aggregation and Grouping of Data",
    "Working with SQL Functions (String, Numeric, Date & Time)",
    "Database Management using MySQL"
  ]
};


export const InstructorData = {
    heading: "Course Instructor",
    name: "Nirmal Pant",
    linkdin:"https://www.linkedin.com/in/datumdiscovery/",
    Instagram:"https://www.instagram.com/datum_discovery/",
    description:"Founder & CEO at Datum Discovery || Corporate Trainer & International Mentor @ Power BI, Tableau, SQL, Data & Business Analytics, Business Intelligence, Data Science || Power BI Consultant || IIM Calcutta"
};

export const InstructorData2 = {
  heading: "Course Instructor",
  name: "Prajakta Gaikwad ",
  linkdin:"https://www.linkedin.com/in/prajakta-gaikwad-33b678198/",
  Instagram:"https://www.linkedin.com/in/prajakta-gaikwad-33b678198/",
  description:"Data Analyst & Corporate Trainer at Datum Discovery 📈| Ex-Fujitsu | Data-Driven Decision Maker with a Strong Engineering Background"
};

export const Course = {
  heading: "Course Curriculum",
  sub_heading: "15 Modules | 247 Sessions",
  courseDetail: [
    {
      panelId: "collapsecourseOne",
      title: "Getting Started with Power BI",
      sessions: "18 Sessions",
      content: [
        { title: "Course Introduction", img: videoLearning },
        { title: "Course Structure", img: videoLearning },
        { title: "What is Data Analytic", img: videoLearning },
        { title: "Who is a Data Analyst", img: videoLearning },
        { title: "Roles & Responsibilities of Data Analyst", img: videoLearning },
        { title: "Steps of Data Analytics", img: videoLearning },
        { title: "What are the Data Analytics tools", img: videoLearning },
        { title: "Power BI vs Tableau vs QlikView", img: videoLearning },
        { title: "Introduction to Power BI", img: videoLearning },
        { title: "Why Power BI?", img: videoLearning },
        { title: "How to get it?", img: videoLearning },
        { title: "Power BI Journey", img: videoLearning },
        { title: "Building Blocks of Power BI", img: videoLearning },
        { title: "Power BI components", img: videoLearning },
        { title: "Power BI Pricing structure", img: videoLearning },
        { title: "The architecture of Power BI", img: videoLearning },
        { title: "Introduction to Power BI Desktop", img: videoLearning },
        { title: "Installation of Power BI Desktop", img: videoLearning }
      ]
    },
    {
      panelId: "collapsecourseThree",
      title: "Launching into the Power BI Journey",
      sessions: "11 Sessions",
      content: [
        { title: "Overview of Power BI Desktop", img: videoLearning },
        { title: "Data Sources in Power BI", img: videoLearning },
        { title: "Using files (Excel, PDF, CSV etc.) as a data source", img: videoLearning },
        { title: "Using SaaS connectors", img: videoLearning },
        { title: "Extracting data from folders, and databases", img: videoLearning },
        { title: "Types of Data Connectors", img: videoLearning },
        { title: "Connecting to a Database", img: videoLearning },
        { title: "Extracting Data from the Web", img: videoLearning },
        { title: "Working with Azure SQL database and database sources", img: videoLearning },
        { title: "Connecting to Analysis Services", img: videoLearning },
        { title: "Other Data Sources (OData, web, SharePoint, etc.)", img: videoLearning }
      ]
    },
    {
      panelId: "collapsecourseFour",
      title: "Data Management with Power Query",
      sessions: "65 Sessions",
      content: [
        { title: "Shaping data using Power Query Editor", img: videoLearning },
        { title: "Formatting data", img: videoLearning },
        { title: "Transformation of Data", img: videoLearning },
        { title: "Understanding of Data types", img: videoLearning },
        { title: "Data profiling for data quality check", img: videoLearning },
        { title: "Working with Parameters", img: videoLearning },
        { title: "Merge Query / Tables", img: videoLearning },
        { title: "Append Query / Tables", img: videoLearning },
        { title: "Group by of Data (Aggregation of Data)", img: videoLearning },
        { title: "Applied steps (Query Settings)", img: videoLearning },
        { title: "Duplicate and Reference tables", img: videoLearning },
        { title: "Transpose of data", img: videoLearning },
        { title: "Fill Columns", img: videoLearning },
        { title: "Pivot & Un-pivot of data", img: videoLearning },
        { title: "Custom Columns", img: videoLearning },
        { title: "Conditional Columns", img: videoLearning },
        { title: "Replace data from the tables", img: videoLearning },
        { title: "Split columns values", img: videoLearning },
        { title: "Move columns and sorting of data", img: videoLearning },
        { title: "Detect data type, count rows, and reverse rows", img: videoLearning },
        { title: "Promote rows as column headers", img: videoLearning },
        { title: "Hierarchies in Power BI", img: videoLearning },
        { title: "Power Query M Language Purpose", img: videoLearning },
        { title: "Data QA & Profiling Tools", img: videoLearning },
        { title: "Text Tools", img: videoLearning },
        { title: "Numerical Tools", img: videoLearning },
        { title: "Date & Time Tools", img: videoLearning },
        { title: "Change Type with Locale", img: videoLearning },
        { title: "Index & Conditional Columns", img: videoLearning },
        { title: "Data Source Settings", img: videoLearning },
        { title: "Refreshing Queries", img: videoLearning },
        { title: "Automating queries with parameters & functions", img: videoLearning },
        { title: "Power Query Architecture and ETL", img: videoLearning },
        { title: "Data Types, Literals & Values", img: videoLearning },
        { title: "Date, Time & Structured Data", img: videoLearning },
        { title: "List, Record & Table Structures", img: videoLearning },
        { title: "Power Query Functions, Parameters", img: videoLearning },
        { title: "Invoke Functions, Execution Results", img: videoLearning },
        { title: "Get Data, Table Creations & Edit", img: videoLearning },
        { title: "Join Kinds, Advance Editor, Apply", img: videoLearning },
        { title: "ETL Operations with Power Query", img: videoLearning },
        { title: "Header Row Promotion", img: videoLearning },
        { title: "Reverse Rows and Row Count", img: videoLearning },
        { title: "Data Type Changes & Detection", img: videoLearning },
        { title: "Replace Columns: Text, non-Text", img: videoLearning },
        { title: "Replace Nulls: Fill Up, Fill Down", img: videoLearning },
        { title: "Move Column", img: videoLearning },
        { title: "Extract, Format, and Numbers", img: videoLearning },
        { title: "Date & Time Transformations", img: videoLearning },
        { title: "Deriving Year, Quarter, Month, Day", img: videoLearning },
        { title: "Add Column: Query Expressions", img: videoLearning },
        { title: "Query Step Inserts and Step Edits", img: videoLearning },
        { title: "Changing Parameters in Power Query", img: videoLearning },
        { title: "Parameters Data Types, Default Lists", img: videoLearning },
        { title: "Static/ Dynamic Lists for Parameters", img: videoLearning },
        { title: "Removing Columns and Duplicates", img: videoLearning },
        { title: "Convert Tables to List Queries", img: videoLearning },
        { title: "Linking Parameters and PBI Canvas", img: videoLearning },
        { title: "Multi-Valued Parameter Lists", img: videoLearning },
        { title: "Creating Lists in Power Query", img: videoLearning },
        { title: "Converting Lists to Table Data", img: videoLearning },
        { title: "Advanced Edits & Parameters", img: videoLearning },
        { title: "Data Type Conversions, Expressions", img: videoLearning },
        { title: "Columns From Examples, Indexes", img: videoLearning },
        { title: "Conditional Columns, Expressions", img: videoLearning }
      ]
    },
    {
      panelId: "collapsecourseFour",
      title: "Data Management with Power Query",
      sessions: "58 Sessions",
      content: [
        { title: "Shaping data using Power Query Editor", img: videoLearning },
        { title: "Formatting data", img: videoLearning },
        { title: "Transformation of Data", img: videoLearning },
        { title: "Understanding of Data types", img: videoLearning },
        { title: "Data profiling for data quality check", img: videoLearning },
        { title: "Working with Parameters", img: videoLearning },
        { title: "Merge Query / Tables", img: videoLearning },
        { title: "Append Query / Tables", img: videoLearning },
        { title: "Group by of Data (Aggregation of Data)", img: videoLearning },
        { title: "Applied steps (Query Settings)", img: videoLearning },
        { title: "Duplicate and Reference tables", img: videoLearning },
        { title: "Transpose of data", img: videoLearning },
        { title: "Fill Columns", img: videoLearning },
        { title: "Pivot & Un-pivot of data", img: videoLearning },
        { title: "Custom Columns", img: videoLearning },
        { title: "Conditional Columns", img: videoLearning },
        { title: "Replace data from the tables", img: videoLearning },
        { title: "Split columns values", img: videoLearning },
        { title: "Move columns and sorting of data", img: videoLearning },
        { title: "Detect data type, count rows, and reverse rows", img: videoLearning },
        { title: "Promote rows as column headers", img: videoLearning },
        { title: "Hierarchies in Power BI", img: videoLearning },
        { title: "Power Query M Language Purpose", img: videoLearning },
        { title: "Data QA & Profiling Tools", img: videoLearning },
        { title: "Text Tools", img: videoLearning },
        { title: "Numerical Tools", img: videoLearning },
        { title: "Date & Time Tools", img: videoLearning },
        { title: "Change Type with Locale", img: videoLearning },
        { title: "Index & Conditional Columns", img: videoLearning },
        { title: "Data Source Settings", img: videoLearning },
        { title: "Refreshing Queries", img: videoLearning },
        { title: "Automating queries with parameters & functions", img: videoLearning },
        { title: "Power Query Architecture and ETL", img: videoLearning },
        { title: "Data Types, Literals & Values", img: videoLearning },
        { title: "Date, Time & Structured Data", img: videoLearning },
        { title: "List, Record & Table Structures", img: videoLearning },
        { title: "Power Query Functions, Parameters", img: videoLearning },
        { title: "Invoke Functions, Execution Results", img: videoLearning },
        { title: "Get Data, Table Creations & Edit", img: videoLearning },
        { title: "Join Kinds, Advance Editor, Apply", img: videoLearning },
        { title: "ETL Operations with Power Query", img: videoLearning },
        { title: "Header Row Promotion", img: videoLearning },
        { title: "Reverse Rows and Row Count", img: videoLearning },
        { title: "Data Type Changes & Detection", img: videoLearning },
        { title: "Replace Columns: Text, non-Text", img: videoLearning },
        { title: "Replace Nulls: Fill Up, Fill Down", img: videoLearning },
        { title: "Move Column", img: videoLearning },
        { title: "Extract, Format, and Numbers", img: videoLearning },
        { title: "Date & Time Transformations", img: videoLearning },
        { title: "Deriving Year, Quarter, Month, Day", img: videoLearning },
        { title: "Add Column: Query Expressions", img: videoLearning },
        { title: "Query Step Inserts and Step Edits", img: videoLearning },
        { title: "Changing Parameters in Power Query", img: videoLearning },
        { title: "Parameters Data Types, Default Lists", img: videoLearning },
        { title: "Static/ Dynamic Lists for Parameters", img: videoLearning },
        { title: "Removing Columns and Duplicates", img: videoLearning },
        { title: "Convert Tables to List Queries", img: videoLearning },
        { title: "Linking Parameters and PBI Canvas", img: videoLearning },
        { title: "Multi-Valued Parameter Lists", img: videoLearning },
        { title: "Creating Lists in Power Query", img: videoLearning },
        { title: "Converting Lists to Table Data", img: videoLearning },
        { title: "Advanced Edits & Parameters", img: videoLearning },
        { title: "Data Type Conversions, Expressions", img: videoLearning },
        { title: "Columns From Examples, Indexes", img: videoLearning },
        { title: "Conditional Columns, Expressions", img: videoLearning }
      ]
    },
    {
      panelId: "collapsecourseFive",
      title: "Data Analysis with DAX & Data Modelling",
      sessions: "36 Sessions",
      content: [
        { title: "Data Modelling Options In DAX", img: videoLearning },
        { title: "Database Normalization", img: videoLearning },
        { title: "Primary & Foreign Keys", img: videoLearning },
        { title: "Relationships vs. Merged Tables", img: videoLearning },
        { title: "Creating Table Relationships", img: videoLearning },
        { title: "Managing & Editing Relationships", img: videoLearning },
        { title: "Relationship Cardinality", img: videoLearning },
        { title: "Hiding Fields from Report View", img: videoLearning },
        { title: "Data Formats & Categories", img: videoLearning },
        { title: "Creating Hierarchies", img: videoLearning },
        { title: "Detecting Relations for DAX", img: videoLearning },
        { title: "Data Model & Star Schema", img: videoLearning },
        { title: "Introduction of DAX", img: videoLearning },
        { title: "Why is DAX used?", img: videoLearning },
        { title: "DAX: Importance in Real-time", img: videoLearning },
        { title: "Real World usage of DAX", img: videoLearning },
        { title: "DAX Architecture, Entity Sets", img: videoLearning },
        { title: "DAX Syntax", img: videoLearning },
        { title: "DAX Operators, Special Characters", img: videoLearning },
        { title: "DAX Data Types", img: videoLearning },
        { title: "Calculated columns using DAX", img: videoLearning },
        { title: "Measures using DAX", img: videoLearning },
        { title: "Implicit vs. Explicit Measures", img: videoLearning },
        { title: "Quick Measures", img: videoLearning },
        { title: "Calculated tables using DAX", img: videoLearning },
        { title: "Creating, and Using Measures with DAX", img: videoLearning },
        { title: "Creating, and Using Columns with DAX", img: videoLearning },
        { title: "Using Calculated Columns in DAX", img: videoLearning },
        { title: "Using Aggregated Measures in DAX", img: videoLearning },
        { title: "Quick Measures", img: videoLearning },
        { title: "DAX functions Categories", img: videoLearning },
        { title: "Aggregation functions", img: videoLearning },
        { title: "Date and time functions", img: videoLearning },
        { title: "Filter functions", img: videoLearning },
        { title: "Logical functions", img: videoLearning },
        { title: "Text functions", img: videoLearning }
      ]
    },
    {
      panelId: "collapsecourseSix",
      title: "Artificial Intelligence features in Power BI",
      sessions: "7 Sessions",
      content: [
        { title: "Introduction to Power Q & A", img: videoLearning },
        { title: "Ask questions about your data using natural query language", img: videoLearning },
        { title: "Getting answers in the form of charts, graphs, and data discovery methodologies", img: videoLearning },
        { title: "Power Q&A best practices", img: videoLearning },
        { title: "Smart Narratives", img: videoLearning },
        { title: "Q&A Visuals", img: videoLearning },
        { title: "Key Influencers", img: videoLearning }
      ]
    },
    {
      panelId: "collapsecourseSeven",
      title: "Executive Reports & Dashboards with Power BI",
      sessions: "4 Sessions",
      content: [
        { title: "Real-time projects: To create Executive reports & Dashboards in Power BI Desktop & Power BI Online", img: videoLearning },
        { title: "Tips & Tricks", img: videoLearning },
        { title: "Story Telling", img: videoLearning },
        { title: "Getting Insights from Your Data", img: videoLearning }
      ]
    },
    {
      panelId: "collapsecourseEight",
      title: "Getting Started with SQL",
      sessions: "13 Sessions",
      content: [
        { title: "Course Introduction", img: videoLearning },
        { title: "Introduction to SQL and Database Fundamentals", img: videoLearning },
        { title: "What is Database & DBMS", img: videoLearning },
        { title: "Types Of Databases", img: videoLearning },
        { title: "Database Tables", img: videoLearning },
        { title: "Data Types", img: videoLearning },
        { title: "Primary Key & Foreign Key", img: videoLearning },
        { title: "Introduction to Relational Databases & RDMS", img: videoLearning },
        { title: "Overview of SQL and its applications", img: videoLearning },
        { title: "Introduction to MySQL", img: videoLearning },
        { title: "Setting up the Environment", img: videoLearning },
        { title: "Installing a database management system (MySQL)", img: videoLearning },
        { title: "Introduction to database tools (MySQL Workbench)", img: videoLearning },
        { title: "MySQL Workbench Overview", img: videoLearning }
      ]
    },
    {
      panelId: "collapsecourseNine",
      title: "SQL Categories",
      sessions: "5 Sessions",
      content: [
        { title: "DDL – Data Definition Language", img: videoLearning },
        { title: "DQL – Data Query Language", img: videoLearning },
        { title: "DML – Data Manipulation Language", img: videoLearning },
        { title: "DCL – Data Control Language", img: videoLearning },
        { title: "TCL – Transaction Control Language", img: videoLearning }
      ]
    },
    {
      panelId: "collapsecourseTen",
      title: "Basic SQL Queries",
      sessions: "9 Sessions",
      content: [
        { title: "Database Creation", img: videoLearning },
        { title: "Table Creation", img: videoLearning },
        { title: "SELECT statements", img: videoLearning },
        { title: "Filtering data with WHERE", img: videoLearning },
        { title: "Sorting data with ORDER BY", img: videoLearning },
        { title: "Selecting specific columns", img: videoLearning },
        { title: "Using aliases for columns", img: videoLearning },
        { title: "Using functions (e.g., COUNT, SUM, AVG)", img: videoLearning },
        { title: "Performing basic arithmetic operations", img: videoLearning }
      ]
    },
    {
      panelId: "collapsecourseEleven",
      title: "Data Manipulation",
      sessions: "6 Sessions",
      content: [
        { title: "INSERT INTO statements", img: videoLearning },
        { title: "Bulk inserts", img: videoLearning },
        { title: "UPDATE statements", img: videoLearning },
        { title: "Conditional updates", img: videoLearning },
        { title: "DELETE statements", img: videoLearning },
        { title: "Conditional deletions", img: videoLearning }
      ]
    },
    {
      panelId: "collapsecourseTwelve",
      title: "Aggregation and Grouping",
      sessions: "5 Sessions",
      content: [
        { title: "Understanding aggregate functions (SUM, AVG, COUNT)", img: videoLearning },
        { title: "Grouping data with GROUP BY", img: videoLearning },
        { title: "Having Clause", img: videoLearning },
        { title: "Working with complex WHERE conditions", img: videoLearning },
        { title: "Using operators (AND, OR, NOT, etc)", img: videoLearning }
      ]
    },
    {
      panelId: "collapsecourseThirteen",
      title: "Advanced Data Retrieval",
      sessions: "5 Sessions",
      content: [
        { title: "Understanding different types of joins (INNER, LEFT, RIGHT, FULL OUTER)", img: videoLearning },
        { title: "Writing join queries", img: videoLearning },
        { title: "Multi-table joins", img: videoLearning },
        { title: "Writing subqueries", img: videoLearning },
        { title: "Using subqueries in SELECT, WHERE, and FROM clauses", img: videoLearning }
      ]
    },
    {
      panelId: "collapsecourseFourteen",
      title: "Advanced SQL Functions",
      sessions: "4 Sessions",
      content: [
        { title: "String Functions - CONCAT, LENGTH, SUBSTRING", img: videoLearning },
        { title: "Pattern matching with LIKE & Wildcard", img: videoLearning },
        { title: "Numeric Functions - ROUND, FLOOR, CEILING", img: videoLearning },
        { title: "Mathematical operations", img: videoLearning }
      ]
    },
    {
      panelId: "collapsecourseFifteen",
      title: "Working with Live Project",
      sessions: "1 Session",
      content: [
        { title: "Power BI with SQL Project", img: videoLearning }
      ]
    }
    
    
    
    
    
  ]
};


export const certification = {
    heading:'Get Certified',
    Image:dautamcertificate
}

export const FaqData = {
  heading: 'Frequently Asked Questions',
  faqs: [
    {
      panelId: 'collapseOne',
      question: '1. What is the SQL and Power BI Mastery Course?',
      answer: 'The SQL and Power BI Mastery Course is a comprehensive program designed to provide in-depth knowledge of both SQL and Power BI, enabling you to analyze, visualize, and manage data effectively.',
    },
    {
      panelId: 'collapseTwo',
      question: '2. How do I enroll in the SQL and Power BI Mastery Course?',
      answer: 'You can enroll in the SQL and Power BI Mastery Course by visiting our website and clicking on the "Enroll Now" button, which will guide you through the registration process.',
    },
    {
      panelId: 'collapseThree',
      question: '3. What prerequisites are required for the SQL and Power BI Mastery Course?',
      answer: 'No prior experience is necessary. However, basic familiarity with databases and data visualization will be helpful. The course is designed to take you from beginner to expert in both SQL and Power BI.',
    },
    {
      panelId: 'collapseFour',
      question: '4. How long will I have access to the course materials?',
      answer: 'Once enrolled, you will have lifetime access to all course materials, allowing you to revisit the content at your own pace.',
    },
    {
      panelId: 'collapseFive',
      question: '5. Will I receive a certificate upon completing the SQL and Power BI Mastery Course?',
      answer: 'Yes, upon successful completion of the course and its assessments, you will receive a certificate of mastery in SQL and Power BI.',
    },
    {
      panelId: 'collapseSix',
      question: '6. Can I access the SQL and Power BI Mastery Course on my mobile device?',
      answer: 'Yes, the course is accessible on any device with an internet connection, including mobile phones, tablets, and computers.',
    },
    {
      panelId: 'collapseSeven',
      question: '7. What is the benefit of learning both SQL and Power BI together?',
      answer: 'Learning SQL and Power BI together enables you to manage and analyze data efficiently. SQL helps you retrieve and manipulate data, while Power BI allows you to visualize and present insights, making you a well-rounded data professional.',
    },
    {
      panelId: 'collapseEight',
      question: '8. Is there any support available during the course?',
      answer: 'Yes, you will have access to instructor support and a community of learners. Additionally, live Q&A sessions are periodically held to help clarify any doubts.',
    },
    {
      panelId: 'collapseNine',
      question: '9. Can I complete the course at my own pace?',
      answer: 'Yes, the course is self-paced, allowing you to learn at your convenience. You can start and complete modules whenever it suits your schedule.',
    },
    {
      panelId: 'collapseTen',
      question: '10. Do I need any special software for the SQL and Power BI Mastery Course?',
      answer: 'No special software is required. The course will guide you through setting up free tools such as Microsoft Power BI Desktop and SQL databases for hands-on practice.',
    }
  ]
};



export const stickyData = {
  headingImg: herobanner,
  indiaCheckout: {
    label: "₹ India Checkout",
    link: "https://rzp.io/l/Pzrm1NJ2F"
  },
  globalCheckout: {
    label: "$ Global Checkout",
    link: "https://buy.stripe.com/fZe7ugbfXciP3wQdRX"
  },
  listHeading: "This course includes:",
  list: [
    {
      img: "https://files.codebasics.io/v3/images-webp/icons/bx-play-circle.webp",
      text: "50 Hrs + On-Demand Videos"
    },
    {
      img: "https://files.codebasics.io/v3/images-webp/icons/bx-file-blank.webp",
      text: "End-to-End Projects for Practice"
    },
    {
      img: "https://files.codebasics.io/v3/images-webp/icons/bx-task.webp",
      text: "Practical Training"
    },
    {
      img: "https://files.codebasics.io/v3/images-webp/icons/bx-mobile-alt.webp",
      text: "Access on any device"
    },
    {
      img: "https://files.codebasics.io/v3/images-webp/icons/bx-trophy.webp",
      text: "Certificate of Completion"
    },
    {
      img: "https://files.codebasics.io/v3/images-webp/icons/bx-hourglass.webp",
      text: "Free Lifetime Access"
    }
  ]
};
