import React from 'react'

const Header = () => {
  return (
    <div class="top_notice_bar">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="d-flex justify-content-center align-items-center py-8">
                        <p class="fs-16 fw-500 mb-0 me-6">
                            For Power BI Consulting & Development Services
                        </p>
                        <div class="d-flex justify-content-center align-items-center gap-2">
                            <p class="mb-0 fw-500"><a href="https://services.datumdiscovery.com/" target="_blank"
                                    class="fw-800 text-primary hover_line">Know More</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)
}

export default Header